<template>
  <Form v-on:submit="login">
    <div class="login-input mb-2">
      <Field
          rules="required|email"
          name="login-email"
          type="text"
          class="form-control"
          v-model="credentials.email"
          v-bind:placeholder="$t('auth.email')"
      />
      <validation-error name="login-email"/>
      <ErrorMessage name="login-email"/>
    </div>
    <div class="login-input mb-2">
      <Field
          rules="required"
          class="form-control"
          type="password"
          name="login-password"
          v-model="credentials.password"
          v-bind:placeholder="$t('auth.password')"
      />
      <validation-error name="login-password"/>
      <ErrorMessage name="login-password"/>
    </div>
    <button class="submit-btn btn btn-primary" type="submit" :disabled="logging_in">
      {{ $t("auth.login_btn") }}
    </button>
  </Form>
</template>

<script>
import ValidationError from "@/components/ValidationError";
import {ErrorMessage, Field, Form} from "vee-validate";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LoginForm",
  components: {
    ValidationError,
    Field,
    Form,
    ErrorMessage,
  },
  data: () => {
    return {
      logging_in:false,
      credentials: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["authLogin","clearErrors"]),
    login: function () {
      this.logging_in = true;
      this.authLogin({
        email: this.credentials.email,
        password: this.credentials.password,
      }).then(() => {
        this.$emit("loginAttempt", true);
      }, () => {
        this.$emit("loginAttempt", false);
      }).finally(() => {
        this.logging_in = false;
      });
    },
  },
  computed: {
    ...mapGetters(["access_token"]),
  },
}
</script>

<style scoped>

</style>
