<template>
  <div @click="logInWithFacebook">
    <slot>
      <button class="button"> Login with Facebook</button>
    </slot>
  </div>
</template>

<script>
export default {
  name: "FacebookLoginButton",
  methods: {
    async logInWithFacebook() {
      window.FB.login( (response) => {
        if (response.authResponse) {
          let accessToken = response.authResponse.accessToken;
          this.$emit('success', accessToken);
        } else {
          this.$emit('fail');
        }
      });
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v13.0"
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  },
  mounted() {
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
  }
};
</script>

<style></style>
