<template>

  <!--   Ordered Group   -->
  <CartGroupLayout :shop="shop" :products="cart_group.products">
    <!-- Header Actions-->
    <template v-slot:header-actions>
      <confirm-modal
          modalDialogClasses="modal-notice"
          v-bind:title="$t('global.notice')"
          v-bind:body="$t('cart.remove_group_modal.body')"
          confirmBtnClass="btn-danger"
          declinedBtnClass="btn-default"
          @confirmed="removeFromCart"
      >
        <button class="btn btn-clean remove font-size-default">
          <i class="far fa-trash-alt font-size-md me-1"></i> {{ $t("global.remove") }}
        </button>
      </confirm-modal>
    </template>

    <!--  Product  -->
    <template v-slot:product="{product}">
      <CartProduct
          :cart_group_id="cart_group.id"
          :cart_updating="cart_updating"
          :product_data="product"
          :shop_id="cart_group.shop_id"
          @delete="removeProductFromCart"
      />
    </template>

    <!--  Footer  -->
    <template v-slot:footer>
      <!--    Total bar    -->
      <CartTotalBar :data_ready="data_ready"
                    :currency="currency"
                    :delivery_price="delivery_price"
                    :total_price="total_price"
                    :total_weight="total_weight"/>
    </template>


    <template v-slot:actions>
      <div class="cart-group-actions">
        <div class='prescription_box' v-if="prescription_required">
          <div class="font-weight-bold text-uppercase color-danger">{{ $t("cart.prescription_required") }}</div>
          <CartPrescriptionUploader v-model="prescription_files"/>
        </div>

        <div v-if="!prescription_required || prescription_files.length !== 0">
          <div v-if="delivery_price === -1">
            <alert type="danger" :dismissible="false">
              <div class="font-weight-bold">
                {{ $t("products.errors.delivery_not_available.title") }}
              </div>
              {{ $t("products.errors.delivery_not_available.body") }}
            </alert>
          </div>

          <div v-if="delivery_price !== -1">
            <!--      Pay in cash      -->
            <authentication-modal v-if="access_token === null">
              <button class="btn btn-primary font-size-sm Aligner"
                      :disabled="data_ready === false"
                      v-if="shop.payment_gateway === 'both' || shop.payment_gateway === 'cash'">
                <img src="@/assets/payment_methods/cash_white.svg"/>
                <span class="aligner">{{ $t("cart.pay.cash") }}</span>
              </button>
            </authentication-modal>
            <template v-else>
              <button class="btn btn-primary font-size-sm Aligner"
                      @click="setPaymentMethod('cash')"
                      :disabled="data_ready === false"
                      v-if="shop.payment_gateway === 'both' || shop.payment_gateway === 'cash'">
                <img src="@/assets/payment_methods/cash_white.svg"/>
                <span class="aligner">{{ $t("cart.pay.cash") }}</span>
              </button>
            </template>

            <!--      Pay Online      -->
            <authentication-modal v-if="access_token === null">
              <button class="btn btn-primary font-size-sm Aligner"
                      :disabled="data_ready === false"
                      v-if="shop.payment_gateway === 'both' || shop.payment_gateway === 'bank'">
                <img src="@/assets/payment_methods/bank_white.svg"/>
                <span class="aligner">{{ $t("cart.pay.card") }}</span>
              </button>
            </authentication-modal>
            <template v-else>
              <button class="btn btn-primary font-size-sm Aligner"
                      @click="setPaymentMethod('bank')"
                      :disabled="data_ready === false"
                      v-if="shop.payment_gateway === 'both' || shop.payment_gateway === 'bank'">
                <img src="@/assets/payment_methods/bank_white.svg"/>
                <span class="aligner">{{ $t("cart.pay.card") }}</span>
              </button>
            </template>
          </div>
        </div>
      </div>
    </template>
  </CartGroupLayout>

</template>

<script>
/*
- We are sending the cart_data to cart API to the the calculations back
- We goo through each cart_data and get each product from the API
 */

import {Promise} from "core-js";
import {formatCartGroupForApi} from "@/includes/cart";
import Alert from "@/components/Alert";
import {mapActions, mapGetters} from "vuex";
import CartPrescriptionUploader from "@/views/Cart/partials/CartPrescriptionUploader";
import ConfirmModal from "@/components/ConfirmModal";
import CartProduct from "@/views/Cart/partials/CartProduct";
import CartTotalBar from "@/views/Cart/partials/CartTotalBar";
import CartGroupLayout from "@/views/Cart/partials/CartGroupLayout";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import AuthenticationModal from "@/components/AuthenticationModal";

export default {
  name: "CartGroup",
  components: {
    AuthenticationModal,
    CartGroupLayout,
    CartTotalBar,
    CartProduct,
    ConfirmModal, CartPrescriptionUploader, Alert
  },
  props: {
    cart_group: {
      default: () => {
        return {};
      },
      type: Object
    },
    selected_address: {
      default: () => {
        return null;
      },
      type: [Number, Boolean]
    }
  },
  data() {
    return {
      cart_updating: false,
      data_ready: false,
      shop: [],
      products: [],
      currency: null,
      delivery_price: 0,
      total_price: 0,
      total_weight: 0,
      prescription_files: this.cart_group.prescription_files ?? [],
      prescription_required: false,
    }
  },
  computed: {
    ...mapGetters(['access_token']),
    formatedCartGroup() {
      return formatCartGroupForApi(this.cart_group, this.selected_address);
    },
  },
  watch: {
    cart_group: {
      handler(new_value) {
        //Update Cart
        this.save_cart_to_storage();
        if (new_value.length !== 0) {
          //Refetch Cart on data changed
          this.refetch_cart();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['removeGroupFromCart', 'removeProductFromGroup', 'save_cart_to_storage', 'setGroupPaymentMethod']),
    setPaymentMethod(payment_method) {
      //Update Cart Group payment_method
      this.setGroupPaymentMethod({
        group_id: this.cart_group.id,
        payment_method: payment_method
      });

      //Go to checkout page
      this.$router.push("/checkout/" + this.cart_group.id);
    },
    checkIfPrescriptionRequired() {
      this.prescription_required = false;
      for (let productKey in this.products) {
        if (this.products[productKey].prescription_required === true) {
          this.prescription_required = true;
        }
      }
    },
    removeProductFromCart(data) {
      this.removeProductFromGroup(data);
    },
    removeFromCart() {
      this.removeGroupFromCart(this.cart_group.id);
    },
    async fetch_cart(cart_block) {
      this.cart_updating = true;
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("fetch_cart");
        window.axios.post(url, cart_block, {
          params: prepareGuestAddressParams()
        }).then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              reject(error.response.data.errors);
            }
        ).finally(() => {
          this.cart_updating = false;
        });
      });
    },
    refetch_cart() {
      this.data_ready = false;
      this.fetch_cart(this.formatedCartGroup).then((response) => {
        this.data_ready = true;
        this.currency = response.data.currency;
        this.shop = response.data.shop;
        this.products = response.data.products;
        this.delivery_price = response.data.delivery_price;
        this.total_price = response.data.total_price;
        this.total_weight = parseFloat(response.data.total_weight.toFixed(2));

        this.checkIfPrescriptionRequired();
      });
    }
  },
  created() {
    this.refetch_cart();
  }
}
</script>

<style lang="scss">
.cart-group-actions {
  .btn {
    width: 100%;
    margin-bottom: map-get($spacings, "default");
    display: flex;

    img {
      float: left;
    }

    span {
      flex-grow: 1;
    }

    &.remove {
      color: map-get($colors, "secondary");
    }
  }
}


@include media-breakpoint-down(lg) {
  .prescription_box {
    margin-top: 10px;
  }
}
</style>
