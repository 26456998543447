<template>
  <div>
    <cart-product-skeleton v-if="!product_ready"/>

    <div class="cart-product row" v-if="product_ready">
      <div class="col-auto image_holder d-none d-md-block">
        <div class="image ratio ratio-4_3">
          <div
              class="body"
              :style="
              'background-image: url(' +
                window.Routes.getImage(product.images[0], 'preview') +
                ');'
            "
          >
            <confirm-modal
                modalDialogClasses="modal-notice"
                v-bind:title="$t('global.notice')"
                v-bind:body="$t('cart.remove_product_modal.body')"
                confirmBtnClass="btn-danger"
                declinedBtnClass="btn-default"
                @confirmed="removeProduct"
            >
              <div class="remove-product"></div>
            </confirm-modal>
          </div>
        </div>
      </div>
      <div class="col">
        <!-- Title -->
        <div class="row justify-content-between">
          <div class="col-lg Aligner items-left title">
            <div class="d-block d-md-none image small-image ratio ratio-4_3">
              <div
                  class="body"
                  :style="
                  'background-image: url(' +
                    window.Routes.getImage(product.images[0], 'preview') +
                    ');'
                "
              >
                <confirm-modal
                    modalDialogClasses="modal-notice"
                    v-bind:title="$t('global.notice')"
                    v-bind:body="$t('cart.remove_product_modal.body')"
                    confirmBtnClass="btn-danger"
                    declinedBtnClass="btn-default"
                    @confirmed="removeProduct"
                >
                  <div class="remove-product"></div>
                </confirm-modal>
              </div>
            </div>

            <span class="font-weight-bold font-size-md">{{
                product.name
              }}</span>
          </div>
          <div class="col-lg-auto">
            <div class="row quantity_row">
              <div class="col col-lg-auto Aligner items-left" v-if="editable">
                <div class="quantity-holder">
                  <input-counter
                      :disabled="cart_updating"
                      :input_disabled="true"
                      :min="1"
                      :max="product.stock !== -1 ? product.stock : null"
                      :step="1"
                      v-model="cart_data.quantity"
                  />
                </div>
              </div>
              <div class="col-auto Aligner">
                <span class="font-weight-bold"
                >{{ formated_price }} x {{ cart_data.quantity }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Details -->
        <div class="row">
          <!-- Preparing time -->
          <div class="col-12">
            <span class="color-secondary"
            >{{ $t("products.preparing_time") }}
            </span>
            {{ product.delay }}
            {{ $t("global.timedata.min") }}
          </div>
          <!-- Variant -->
          <div class="col-12" v-if="selected_variant !== null">
            <span class="color-secondary">{{ selected_variant.variant }}</span>
            : {{ selected_variant.type }}
          </div>
        </div>

        <!-- Extras -->
        <CartProductExtras
            :disabled="cart_updating"
            :editable="editable"
            :selected_only="selected_extras_only"
            v-model="cart_data.product_extras"
            :product_extras="product.product_extras"
        />

        <!--   SubTotal   -->
        <div class="row subtotal">
          <div class="col-12 text-end">
            <span class="text-uppercase color-secondary pe-2"
            >{{ $t("global.price.subtotal") }}
            </span>
            <span class="font-weight-bold font-size-md">
              {{ formatPrice(product.currency, total_price) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCounter from "@/components/CustomInputs/InputCounter";
import CartProductExtras from "@/views/Cart/partials/CartProductExtras";
import formatPrice from "@/includes/currency";
import {calculatePrice} from "@/includes/cart";
import {mapActions} from "vuex";
import CartProductSkeleton from "@/views/Cart/partials/CartProductSkeleton";
import ConfirmModal from "@/components/ConfirmModal";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";

export default {
  name: "CartProduct",
  components: {
    ConfirmModal,
    CartProductSkeleton,
    CartProductExtras,
    InputCounter
  },
  props: {
    cart_updating: {
      default: () => {
        return false;
      },
      type: Boolean
    },
    shop_id: {
      default: () => {
        return null;
      },
      type: [Number]
    },
    cart_group_id: {
      default: () => {
        return null;
      },
      type: [Number]
    },
    editable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    selected_extras_only: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    product_data: {
      default: () => {
        return {};
      },
      type: Object
    }
  },
  computed: {
    formated_price: function () {
      let product_price = this.product.price;
      if (this.selected_variant !== null) {
        product_price += this.selected_variant.extra_price;
      }
      return formatPrice(this.product.currency, product_price);
    },
    selected_variant: function () {
      //Get the first product variant
      let variant = this.product.product_variants[0];
      //Check if product has variants at all
      if (variant === undefined) {
        return null;
      }
      for (const variantTypeKey in variant.variants) {
        if (variant.variants[variantTypeKey]["id"] === this.cart_data.variant) {
          return {
            variant: variant.name,
            type: variant.variants[variantTypeKey].name,
            extra_price: variant.variants[variantTypeKey]["extra_price"]
          };
        }
      }

      return null;
    }
  },
  data: () => {
    return {
      cart_data: {},
      product_ready: false,
      product: [],
      product_errors: [],
      total_price: 0
    };
  },
  methods: {
    ...mapActions(["save_cart_to_storage"]),
    removeProduct() {
      this.$emit("delete", {
        group_id: this.cart_group_id,
        product_id: this.cart_data.product_id
      });
    },
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
    async getProductFromAPI() {
      //Clean data
      this.product_ready = false;
      this.product = [];
      this.product_errors = [];
      return new Promise((resolve, reject) => {
        window.axios
            .get(
                window.Routes.getRoute("product_show", {
                  product_id: this.product_data.product_id
                }), {
                  params: prepareGuestAddressParams()
                }
            )
            .then(
                response => {
                  resolve(response.data.data);
                  this.product_ready = true;
                  this.product = response.data.data;
                  this.product_errors = response.data.meta;
                },
                error => {
                  reject(error.response.data.errors);
                  console.log(error.response.data.errors);
                }
            );
      });
    }
  },
  watch: {
    cart_data: {
      handler() {
        //Calculate total price
        if (this.product_ready === true) {
          this.total_price = calculatePrice(this.product, this.cart_data, true)[
              "price"
              ];
        } else {
          this.total_price = 0;
        }
        //Update Cart
        this.save_cart_to_storage();
        // this.getProduct(
        //     {
        //       "shop_id" : this.shop_id,
        //       "delivery_type" : this.cart_data.delivery_type,
        //       "product_id" : this.cart_data.product_id,
        //       "variant_id" : this.cart_data.variant,
        //       "product_data" : this.product_data
        //     }
        // );
      },
      deep: true
    }
  },
  created() {
    this.cart_data = this.product_data;
    //Get Product
    this.getProductFromAPI().then(() => {
      //Calculate total price
      this.total_price = calculatePrice(this.product, this.cart_data, true)[
          "price"
          ];
    });
  }
};
</script>

<style lang="scss" scoped>
.cart-product {
  .image {
    width: 100px;
    border: 1px solid map-get($defaults, "border-color");
    border-radius: map-get($defaults, "border-radius");

    .body {
      background-size: cover;
      border-radius: map-get($defaults, "border-radius");

      .remove-product {
        position: absolute;
        top: -10px;
        right: -8px;
        width: 21px;
        height: 21px;
        background-image: url("../../../assets/remove.svg");
        background-repeat: no-repeat;
        background-size: cover;

        &:hover {
          cursor: pointer;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .title {
    margin-bottom: map-get($spacings, "xsmall");
  }

  .quantity_row {
    .quantity-holder {
      width: 100px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .cart-product {
    .small-image {
      width: 50px;
      margin-right: map-get($spacings, "small");
    }

    .quantity_row {
      .quantity-holder {
        margin-top: map-get($spacings, "xsmall");
        margin-bottom: map-get($spacings, "small");
        width: 100px;
      }
    }
  }
}
</style>
