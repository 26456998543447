<template>
  <div class="container cart-container">
    <div class="row header">
      <div class="col">
        <div class="title font-size-xl font-weight-bold">
          {{ $t("cart.title") }}
          <span class="color-twiks ps-2"> {{ cartProductsCounter }} </span>
        </div>
      </div>
      <div class="col-auto Aligner">
        <confirm-modal
            modalDialogClasses="modal-notice"
            v-bind:title="$t('global.notice')"
            v-bind:body="$t('cart.empty_cart_modal.body')"
            confirmBtnClass="btn-danger"
            declinedBtnClass="btn-default"
            @confirmed="clearCart"
        >
          <button class="btn btn-clean remove_btn font-size-default">
            <i class="far fa-trash-alt font-size-md me-1"></i>
            {{ $t("cart.clear_cart") }}
          </button>
        </confirm-modal>
      </div>
    </div>

    <div class="cart-groups" v-if="primaryLocation !== null || guestAddress != null">
      <cart-group
          v-for="cart_group in cart"
          :key="cart_group.id"
          :cart_group="cart_group"
          :selected_address="primaryLocation ? primaryLocation : null"
      />
    </div>
  </div>
</template>

<script>
/*
An address is needed to do the cart calculations so: we will wait for the primaryAddress and send it
to the cart-group component
 */

import {mapActions, mapGetters} from "vuex";
import CartGroup from "@/views/Cart/partials/CartGroup";
import ConfirmModal from "@/components/ConfirmModal";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "CartShow",
  components: {ConfirmModal, CartGroup},
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("cart.page_title"),
    })
  },
  computed: {
    ...mapGetters(["cart", "cartProductsCounter", "primaryAddress", "guestAddress"]),
    //Primary location
    primaryLocation: function () {
      if (this.primaryAddress != null) {
        return this.primaryAddress.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions(["emptyCart"]),
    clearCart: function () {
      this.emptyCart();
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-container {
  .header {
    .title {
      padding-top: map-get($spacings, "default");
      padding-bottom: map-get($spacings, "default");
    }

    .remove_btn {
      color: map-get($colors, "secondary");
    }
  }
}
</style>


