<template>
  <div class="ordered-group">
    <div class="ordered-group-header row justify-content-between">
      <div class="col-auto shop Aligner">
        <div class="image ratio"
             :style="'background-image:url('+ window.Routes.getImage(shop.logo, 'thumbnail') +')'"></div>
        <span class="font-weight-bold">
          <router-link class="color-black hover-color-black" v-if='shop.length !== 0' :to="'/shops/' + shop.id + '?title=' + shop.name">
            {{ shop.name }}
          </router-link>
        </span>
        <div class="skeleton" v-if="shop.length === 0">
          <div class="line skeleton_loader dark thick w-100px"></div>
        </div>
      </div>
      <div class="col-auto actions Aligner">
        <slot name="header-actions"></slot>
      </div>
    </div>
    <div class="ordered-group-body row">
      <!--   Left side (Products)   -->
      <div class="col-md left-side">
        <!--   Products Skeleton  -->
        <div class="products" v-if="products.length === 0">
          <div class="product-holder">
            <cart-product-skeleton/>
          </div>
        </div>
        <!--   Products   -->
        <div class="products" v-if="products.length !== 0">
          <div class="product-holder" :id="'cart_product_'+product.product_id" v-for="product in products"
               :key="product.product_id">
            <slot name="product" v-bind:product="product">
              Use <em>v-slot:product="{product}"</em> to get the product from this slot!
            </slot>
          </div>
        </div>

        <!--     Footer     -->
        <slot name="footer"></slot>
      </div>

      <!--   Cart Actions   -->
      <div class="col-md-auto actions">
        <div class="wrapper">

          <!--      Actions      -->
          <slot name="actions">

          </slot>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartProductSkeleton from "@/views/Cart/partials/CartProductSkeleton";

export default {
  name: "CartGroupLayout",
  components: {CartProductSkeleton},
  props: {
    shop: {
      type: Object,
      default: () => {
        return {};
      }
    },
    products: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.ordered-group {
  margin-top: map-get($spacings, "medium");
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }

  &-header {
    border-top: 1px solid map-get($defaults, "border-color");
    background-color: map-get($colors, "lightGray");
    padding: map-get($spacings, "xsmall") map-get($spacings, "default");
    margin: 0;

    & > * {
      padding-left: 0;
      padding-right: 0;
    }

    .shop {
      .image {
        width: 40px;
        border: 1px solid map-get($defaults, "border-color");
        background-color: map-get($colors, "white");
        border-radius: 40px;
        background-size: cover;
        background-position: center;
        margin-right: map-get($spacings, "small");
      }
    }

    .actions {
      .btn {
      }
    }
  }

  &-body {
    padding: map-get($spacings, "default");

    .left-side {
      padding-left: 100px;

      .products {
        .product-holder {
          border-bottom: 1px solid map-get($defaults, "border-color");
          padding-top: map-get($spacings, "small");
          padding-bottom: map-get($spacings, "small");

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }
    }

    .actions {
      .wrapper {
        width: 250px;

        .btn {
          width: 100%;
          margin-bottom: map-get($spacings, "default");
          display: flex;

          img {
            float: left;
          }

          span {
            flex-grow: 1;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

    }
  }
}

@include media-breakpoint-down(lg) {
  .ordered-group {
    &-body {
      .left-side {
        padding-left: map-get($spacings, "default");
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .ordered-group {
    &-body {
      .actions {
        .wrapper {
          width: 100%;
        }
      }
    }
  }
}
</style>
