<template>
  <div v-if="renderParams && !logoutButton" @click="handleClick" :id="id"></div>
  <div v-else @click="handleClick" :id="id">
    <slot>
      <button class="button"> Login with Google</button>
    </slot>
  </div>
</template>

<script>
let componentId = 0;
import GoogleAuth from "@/includes/GoogleAuth";
export default {
  name: 'GoogleLoginButton',
  props: {
    onCurrentUser: {
      type: Function,
      default: () => { }
    },
    logoutButton: {
      type: Boolean,
      default: false
    },
    renderParams: {
      type: Object,
      required: false
    }
  },
  methods: {
    handleClick() {
      const method = this.logoutButton ? 'signOut' : 'signIn';
      GoogleAuth[method]().then(() => {
        let access_token = window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true).access_token;
        this.$emit('success', access_token);
      }).catch(err => {
        console.log(err);
        this.$emit('fail', err);
      });
    }
  },
  beforeMount(){
    this.id = `google-signin-btn-${componentId++}`;
  },
  mounted() {
    GoogleAuth.load({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
    }).then(() => {
      if (this.renderParams && this.logoutButton === false) {
        window.gapi.signin2.render(this.id, this.renderParams);
      }
      if (GoogleAuth.isSignedIn()) {
        this.onCurrentUser(GoogleAuth.currentUser());
      }
    }).catch(err => {
      console.log(err);
    });
  }
}
</script>

<style>
</style>
