<template>
  <div class="social-login">
    <!--    Social Login      -->
    <loading :loading="awaiting_social_auth"/>
    <template v-if="!awaiting_social_auth">
      <facebook-login-button @success="onSocialLogin('facebook', $event)" @fail="onSocialLoginFail">
        <icon-button classes="btn btn-blue font-size-sm mb-2" :image="require('@/assets/auth/facebook.png')"
                     :text="$t('auth.app-stores.facebook')"/>
      </facebook-login-button>
      <google-login-button @success="onSocialLogin('google', $event)" @fail="onSocialLoginFail">
        <icon-button classes="btn btn-danger font-size-sm mb-2" :image="require('@/assets/auth/google.png')"
                     :text="$t('auth.app-stores.google')"/>
      </google-login-button>
    </template>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import FacebookLoginButton from "@/views/Auth/partials/FacebookSocialLogin";
import IconButton from "@/components/IconButton";
import GoogleLoginButton from "@/views/Auth/partials/GoogleSocialLogin";
import {mapActions} from "vuex";
export default {
  name: "SocialLogin",
  components: {GoogleLoginButton, IconButton, FacebookLoginButton, Loading},
  data() {
    return {
      awaiting_social_auth: false,
    }
  },
  methods: {
    ...mapActions(['authSocialLogin']),
    onSocialLoginFail(){
      this.awaiting_social_auth = false;
    },
    onSocialLogin(provider, access_token) {
      this.awaiting_social_auth = true;
      this.authSocialLogin({
        provider: provider,
        access_token: access_token,
      }).finally(() => {
        this.awaiting_social_auth = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
