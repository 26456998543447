<template>

  <span @click="showModal">
  <slot></slot>
  </span>

  <div class="auth-modal-wrapper">
    <!-- Modal -->
    <div ref='modal' class="modal fade" :id="'auth_modal_'+unique_id" tabindex="-1"
         :aria-labelledby="'auth_modal_label_'+unique_id" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-notice">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" :id="'auth_modal_label_' + unique_id">
              {{ active_form === 'login' ? $t("auth.login_btn") : $t("auth.register_btn") }}
            </h5>
            <i
                class="fas fa-times-circle close mt-1"
                v-bind:aria-label="$t('global.cancel')"
                data-bs-dismiss="modal"
            ></i>
          </div>

          <div class="modal-body">
            <alert v-if="authFailed">
              {{ $t("auth.check_credentials") }}
            </alert>
            <!--     Login     -->
            <div class="login-form" v-show="active_form === 'login'">
              <login-form @loginAttempt="loginAttempt"/>

              <div class="font-size-sm py-2">
                {{ $t("auth.login.head") }}
                <a href="#" @click="showForm('register')">
                  {{ $t("auth.login.register") }}
                </a>
              </div>
            </div>

            <!--     Registration form     -->
            <div class="registration-form" v-show="active_form === 'register'">
              <registration-form @registrationAttempt="registrationAttempt"/>

              <div class="font-size-sm py-2">
                {{ $t("auth.register.head") }}
                <a href="#" @click="showForm('login')">
                  {{ $t("auth.register.login") }}
                </a>
              </div>
            </div>

            <!--     Social Login     -->
            <hr>
            <social-login/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import LoginForm from "@/views/Auth/partials/LoginForm";
import RegistrationForm from "@/views/Auth/partials/RegistrationForm";
import SocialLogin from "@/views/Auth/partials/SocialLogin";
import Alert from "@/components/Alert";

export default {
  name: "AuthenticationModal",
  components: {Alert, SocialLogin, RegistrationForm, LoginForm},
  setup() {
    const modal = ref(null);

    return {
      modal
    };
  },
  data() {
    return {
      bs_modal: null,
      unique_id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
      active_form: 'login',
      authFailed: false
    }
  },
  methods: {
    showModal() {
      if (this.bs_modal === null) {
        this.bs_modal = new window.bootstrap.Modal(this.modal, {});
      }
      this.bs_modal.show();
    },
    showForm(form) {
      this.active_form = form;
    },
    loginAttempt(status){
      if(status === false){
        this.authFailed = true;
      } else {
        this.authFailed = false;
        this.bs_modal.hide();
      }
    },
    registrationAttempt(status){
      if(status === false){
        this.authFailed = true;
      } else {
        this.authFailed = false;
        this.bs_modal.hide();
      }
    }
  },
}
</script>

<style lang="scss">
.auth-modal-wrapper {
  .modal {
    .modal-body {
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
